import React, { useState, useEffect } from 'react'
import { Button, Typography, CircularProgress, Paper, Stack, Chip } from '@mui/material'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Divider from '@mui/material/Divider';
import { getAccess, setAccess as APISetUserAccess } from '../../apiConnector'
import { eventManager } from '../../eventManager';
import { useError } from '../contexts/ErrorContext';
import publicIp from 'public-ip'

const AccessTile = ({ Data }) => {
    const [access, setAccess] = useState(false)
    const [accessGCP, setAccessGCP] = useState(false)
    const [loading, setLoading] = useState(true)
    const [currentIP, setCurrentIP] = useState(null)

    const { showError, hasError } = useError();

    useEffect(() => {
        const handleError = (error) => { showError(error); };
        eventManager.subscribe('api-error', handleError);
        return () => { eventManager.unsubscribe('api-error', handleError); };
    }, []);

    const fetchData = async () => {
        let ip = null;
        ip = await getUserIP()
        if (ip === null) ip = await publicIp.v4()
        const data = await getAccess(ip)

        if (hasError.current) return;

        if (data) {
            setAccessGCP(data.data.gcpAccess || data.data.isOffice)
            setAccess(data.data.isOffice || data.data.gcpAccess)
            setCurrentIP(ip)
        }
        setLoading(false)
    };

    useEffect(() => {
        fetchData();
    }, [Data]);

    const getUserIP = async () => {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            return data.ip;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    const refreshAccess = async (e) => {
        setLoading(true)
        const publicIP = await publicIp.v4()
        const setResp = await APISetUserAccess({ ...Data, ip: [publicIP] });
        const data = await getAccess(currentIP)
        if (data) {
            setAccessGCP(data.data.gcpAccess || data.data.isOffice)
            setAccess(data.data.isOffice || data.data.gcpAccess)
        }
        // console.log(access)
        setLoading(false)
    };

    if (loading) {
        return (
            <Paper sx={{ p: 2, width: 400 }} elevation={4}>
                <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
                    <Typography variant="body1" >Please disable script blocking on this site.<br />Otherwise, refreshing may not function properly.</Typography>
                    <CircularProgress />
                </Stack>
            </Paper>
        )
    }

    return (
        <Paper sx={{ p: 2, width: 400 }} elevation={4}>
            <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                    <Typography variant="body1">IP:</Typography>
                    <Chip variant="outlined" label={currentIP} />
                </Stack>
                <Divider sx={{ width: '100%' }} />
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                    <Typography variant="body1">Access:</Typography>
                    {accessGCP ? <Chip color="success" label="OK" /> : <Chip color="error" label="UNAVAILABLE" />}
                </Stack>
                {access ?
                    <></>
                    :
                    <>
                        <Divider sx={{ width: '100%' }} />
                        <Button sx={{ borderRadius: 28 }} color="primary" variant="contained" startIcon={<AutorenewIcon />} onClick={refreshAccess}>Refresh Access</Button>
                    </>
                }
            </Stack>
        </Paper >
    )
}

export default AccessTile
