import React, { useState, useEffect, useMemo } from 'react';
import { TextField, FormLabel, FormControlLabel, Switch, Stack } from '@mui/material/';

const UserServiceAccessSelect = ({ services, onSelect }) => {

    // const [services, setServices] = useState([]);

    const handleServiceToggle = (service) => (event) => {
        onSelect(prevServices => {
            const isServiceIncluded = prevServices.includes(service);
            if (isServiceIncluded) { return prevServices.filter(s => s !== service); }
            else { return [...prevServices, service]; }
        });
    };

    return (
        <>
            <FormLabel>Services</FormLabel>
            <Stack direction="row" spacing={2} alignItems="center">
                <FormControlLabel control={<Switch checked={services?.includes("svn")} onChange={handleServiceToggle('svn')} />} label="SVN" />
                <FormControlLabel control={<Switch checked={services?.includes("jenkins")} onChange={handleServiceToggle('jenkins')} />} label="Jenkins" />
                <FormControlLabel control={<Switch checked={services?.includes("upackage")} onChange={handleServiceToggle('upackage')} />} label="UPM" />
                <FormControlLabel control={<Switch checked={services?.includes("http")} onChange={handleServiceToggle('http')} />} label="HTTP" />
                <FormControlLabel control={<Switch checked={services?.includes("ssh")} onChange={handleServiceToggle('ssh')} />} label="SSH" />
                <FormControlLabel control={<Switch checked={services?.includes("rdp")} onChange={handleServiceToggle('rdp')} />} label="RDP" />
                <FormControlLabel control={<Switch checked={services?.includes("hports")} onChange={handleServiceToggle('hports')} />} label="High Ports" />
            </Stack>
        </>)
}

export default UserServiceAccessSelect
