import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';

const SaveButton = ({ active, onSave, label = "Save" }) => {
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        if (active && !loading) {
            setLoading(true);
            await onSave();
            setLoading(false);
        }
    };

    return (
        <Button
            sx={{ borderRadius: 28 }}
            color="primary"
            variant={loading ? "outlined" : "contained"}
            disabled={!active || loading}
            onClick={handleClick}
        >
            {loading ? <CircularProgress size={24} color="primary" /> : label}
        </Button>
    );
};

export default SaveButton;
