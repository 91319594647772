import React, { useState, useMemo } from 'react';
import { CardMedia, Button, Card, Typography, CardContent, CardActions, Avatar, CardHeader, Snackbar, Alert } from '@mui/material/';
import { deepPurple, blue, orange } from '@mui/material/colors';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { makeStyles } from '@mui/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ApartmentIcon from '@mui/icons-material/Apartment';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const useStyles = makeStyles((theme) => ({
    linkText: {
        wordBreak: 'break-all',
    }
}));

const getFullLink = (text) => { return "svn://svn.immersion.link/" + text + "/trunk"; }

const getAvatar = (name) => {
    const prefix = name.substring(0, 4);

    switch (prefix) {
        case "imme": return <Avatar sx={{ bgcolor: blue[500] }}><ViewInArIcon /></Avatar>;
        case "imga": return <Avatar sx={{ bgcolor: deepPurple[500] }}><SportsEsportsIcon /></Avatar>;
        case "imre": return <Avatar sx={{ bgcolor: orange[500] }}><ApartmentIcon /></Avatar>;
        default: return <Avatar sx={{ bgcolor: common[500] }}><QuestionMarkIcon /></Avatar>;
    }
}

const getType = (name) => {
    const prefix = name.substring(0, 4);

    switch (prefix) {
        case "imme": return "Immersion";
        case "imga": return "Immersion Games";
        case "imre": return "Immersion Real Estate";
        default: return "N/A";
    }
}

const generateTitle = (text) => {

    const str = text.substring(5);
    const wordsArray = str.split('_');
    var result = "";

    for (var i = 0; i < wordsArray.length; i++) {
        var word = wordsArray[i].toLowerCase();
        result += word.substr(0, 1).toUpperCase() + word.substr(1) + " ";
    }

    return result;
}

const ProjectTile = ({ ProjectData }) => {

    const [open, setOpen] = useState(false);

    const classes = useStyles();
    const fullLink = useMemo(() => getFullLink(ProjectData.name), [ProjectData.name]);
    const title = useMemo(() => generateTitle(ProjectData.name), [ProjectData.name]);
    const avatar = useMemo(() => getAvatar(ProjectData.name), [ProjectData.name]);
    const type = useMemo(() => getType(ProjectData.name), [ProjectData.name]);

    return (
        <Card sx={{ maxWidth: 350 }} elevation={10}>
            <CardHeader avatar={avatar} title={<Typography variant="h6">{title}</Typography>} subheader={type} />
            <CardMedia
                component="img"
                height="80"
                image="https://png.pngtree.com/thumb_back/fw800/back_pic/04/15/94/285823f61c7ed73.jpg"
                title={title}
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" className={classes.linkText}>{fullLink}</Typography>
            </CardContent>
            <CardActions>
                <CopyToClipboard onCopy={() => setOpen(true)} text={fullLink}>
                    <Button color="primary" variant="outlined" sx={{ borderRadius: 28 }} startIcon={<ContentCopyIcon />}>Copy Link</Button>
                </CopyToClipboard>
            </CardActions>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={open} autoHideDuration={5000} onClose={() => setOpen(false)} message="Link Copied!">
                <Alert severity="success" variant="filled"                >
                Copied to clipboard: {fullLink}
                </Alert>
            </Snackbar>
        </Card>
    );
};

export default ProjectTile
