import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom'
import { Button, Paper, Stack, Typography, Chip, Avatar, CircularProgress } from '@mui/material'
import { deepPurple, blue, orange, common } from '@mui/material/colors';
import { getProject, getUser, getGroup, setProject, updateRepositoriesAccess, archiveRepository, restoreRepository, setProjectState, deleteProject } from '../../../apiConnector'
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ApartmentIcon from '@mui/icons-material/Apartment';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import BackButton from '../../items/BackButton';
import ItemsSelect from '../../items/ItemsSelect';
import LoadingPlaceholder from '../../items/LoadingPlaceholder';
import ConfirmDialog from '../../dialogs/ConfirmDialog';
import { useNotification } from '../../contexts/NotificationContext';
import SaveButton from '../../items/SaveButton';

const getFullLink = (text) => { return "svn://svn.immersion.link/" + text + "/trunk"; }

const getAvatar = (name) => {
    const prefix = name.substring(0, 4);

    switch (prefix) {
        case "imme": return <Avatar sx={{ bgcolor: blue[500] }}><ViewInArIcon /></Avatar>;
        case "imga": return <Avatar sx={{ bgcolor: deepPurple[500] }}><SportsEsportsIcon /></Avatar>;
        case "imre": return <Avatar sx={{ bgcolor: orange[500] }}><ApartmentIcon /></Avatar>;
        default: return <Avatar sx={{ bgcolor: common[500] }}><QuestionMarkIcon /></Avatar>;
    }
}

const generateTitle = (text) => {

    const str = text.substring(5);
    const wordsArray = str.split('_');
    var result = "";

    for (var i = 0; i < wordsArray.length; i++) {
        var word = wordsArray[i].toLowerCase();
        result += word.substr(0, 1).toUpperCase() + word.substr(1) + " ";
    }

    return result;
}

const EditProject = ({ match }) => {

    const [projectData, setProjectData] = useState({});
    const [usersSelected, setUsersSelected] = useState([]);
    const [groupsSelected, setGroupsSelected] = useState([]);
    const [projectUsers, setProjectUsers] = useState([]);
    const [projectGroups, setProjectGroups] = useState([]);
    const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
    const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [loading, setLoading] = useState(true);

    const history = useHistory();
    const { addNotification } = useNotification();
    const id = match.params.id;

    const title = useMemo(() => projectData?.name ? generateTitle(projectData.name) : "", [projectData.name]);
    const name = useMemo(() => projectData?.name ? projectData?.name : "", [projectData.name]);
    const state = useMemo(() => projectData?.state ? projectData?.state : "", [projectData.state]);

    const fetchData = async () => {
        const pData = (await getProject(id)).data;

        if (pData.users) {
            const pUsers = pData.users.length > 0 ? (await getUser(pData.users)).data : [];
            setProjectUsers(pUsers);
            setUsersSelected(pUsers.map(user => user.id));
        }

        if (pData.groups) {
            const pGroups = pData.groups.length > 0 ? (await getGroup(pData.groups)).data : [];
            setProjectGroups(pGroups);
            setGroupsSelected(pGroups.map(group => group.id));
        }

        if (pData) { setProjectData(pData); }

        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const handleSave = async (e) => {
        await setProject(id, { users: usersSelected, groups: groupsSelected });
        await updateRepositoriesAccess();
        await fetchData();
        addNotification("Project Saved!");
    };

    const handleArchive = () => { setOpenArchiveDialog(true); };
    const handleRestore = () => { setOpenRestoreDialog(true); };
    const handleDelete = () => { setOpenDeleteDialog(true); };

    const handleConfirmArchive = async () => {
        await archiveRepository(id);
        // await updateRepositoriesAccess()
        setProjectState(id, { state: "ARCHIVING" });
        addNotification("Project is set for archivization.");
        setOpenArchiveDialog(false);
        history.goBack();
    };

    const handleConfirmRestore = async () => {
        await restoreRepository(id);
        addNotification("Project is set for restoration.");
        setProjectState(id, { state: "RESTORING" });
        setOpenRestoreDialog(false);
        history.goBack();
    };

    const handleConfirmDelete = async () => {
        await deleteProject(id);
        addNotification("Project Deleted.");
        setOpenDeleteDialog(false);
        history.goBack();
    };

    const activeChanges = useMemo(() => {
        const projectUserIds = new Set(projectUsers.map(user => user.id));
        const projectGroupIds = new Set(projectGroups.map(group => group.id));
        const usersSelectedSet = new Set(usersSelected);
        const groupsSelectedSet = new Set(groupsSelected);

        const usersMatch = usersSelectedSet.size === projectUserIds.size && [...usersSelectedSet].every(id => projectUserIds.has(id));
        const groupsMatch = groupsSelectedSet.size === projectGroupIds.size && [...groupsSelectedSet].every(id => projectGroupIds.has(id));

        return usersMatch && groupsMatch;
    }, [projectUsers, projectGroups, usersSelected, groupsSelected]);

    if (loading) { return <LoadingPlaceholder />; }
    if (state !== "ACTIVE" && state !== "ARCHIVED") {
        return (
            <>
                <Paper sx={{ p: 2 }} elevation={4}>
                    <Stack direction="row" spacing={1}>
                        <BackButton />
                    </Stack>
                    <Stack spacing={1} sx={{ mt: 1, mb: 2 }} alignItems="center" justifyContent="center">
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                            <CircularProgress />
                            <Typography variant="h4">{title}</Typography>
                            <Chip variant="outlined" label={state} />
                        </Stack>
                    </Stack>
                </Paper>
            </>
        )
    }

    return (
        <>
            <Paper sx={{ p: 2 }} elevation={4}>
                <Stack direction="row" spacing={1}>
                    <BackButton />
                </Stack>
                <Stack spacing={1} sx={{ mt: 1, mb: 2 }} alignItems="center" justifyContent="center">

                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                        {getAvatar(name)}
                        <Typography variant="h4">{title}</Typography>
                        <Chip variant="outlined" label={state} />
                    </Stack>
                    <Typography variant="body1">{name}</Typography>
                    <Typography variant="body2">{getFullLink(name)}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 2 }} alignItems="center" justifyContent="center">
                    {state === "ARCHIVED" ?
                        <>
                            <Button sx={{ borderRadius: 28 }} variant="contained" color="success" onClick={handleRestore}>RESTORE</Button>
                            <Button sx={{ borderRadius: 28 }} variant="outlined" color="error" onClick={handleDelete}>DELETE</Button>
                        </>
                        :
                        <>
                            <SaveButton active={!activeChanges} onSave={handleSave} />
                            <Button sx={{ borderRadius: 28 }} color="error" variant="outlined" onClick={handleArchive}>ARCHIVE</Button>
                        </>
                    }
                </Stack>
            </Paper>
            <ItemsSelect dataFetcher={getUser} onSelect={setUsersSelected} defaultSelected={usersSelected} sx={{ mt: 1 }} label="Users" />
            <ItemsSelect dataFetcher={getGroup} onSelect={setGroupsSelected} defaultSelected={groupsSelected} sx={{ mt: 1 }} label="Groups" />

            <ConfirmDialog
                open={openArchiveDialog}
                header="Confirm Archivisation"
                message="Are you sure you want to archive this project?"
                onConfirm={handleConfirmArchive}
                onCancel={() => setOpenArchiveDialog(false)}
            />

            <ConfirmDialog
                open={openRestoreDialog}
                header="Confirm Restoring"
                message="Are you sure you want to restore this project?"
                onConfirm={handleConfirmRestore}
                onCancel={() => setOpenRestoreDialog(false)}
            />

            <ConfirmDialog
                open={openDeleteDialog}
                header="Confirm Deletion"
                message="Are you sure you want to permanently delete this project?"
                onConfirm={handleConfirmDelete}
                onCancel={() => setOpenDeleteDialog(false)}
            />
        </>
    )
}

export default EditProject