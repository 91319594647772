import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Divider, Button, Typography, Paper, Stack, Chip } from '@mui/material';
import { blue, red } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import ReportIcon from '@mui/icons-material/Report';
import ConfirmDialog from '../../dialogs/ConfirmDialog';
import { useNotification } from '../../contexts/NotificationContext';
import { getLog, deleteLog } from '../../../apiConnector';
import LoadingPlaceholder from '../../items/LoadingPlaceholder';
import BackButton from '../../items/BackButton';

const EditLog = ({ match }) => {
    const [log, setLog] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(true);

    const history = useHistory();
    const { addNotification } = useNotification();
    const id = match.params.id;

    const icon = () => {
        switch (log?.severity) {
            case "INFO": return <InfoIcon sx={{ color: blue[500] }} />;
            case "ERROR": return <ReportIcon sx={{ color: red[500] }} />;
            default: return <></>
        }
    }

    const date = () => {
        const milliseconds = log?.timeStamp._seconds * 1000 + log?.timeStamp._nanoseconds / 1000000;
        const timeStampDate = milliseconds ? new Date(milliseconds) : 0;

        return new Intl.DateTimeFormat('pl-PL', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timeStampDate);
    };

    const fetchData = async () => {
        const logData = (await getLog(id)).data;
        setLog(logData);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const handleDelete = () => { setOpenDialog(true); };

    const handleConfirmDelete = async () => {
        addNotification("Log Deleted!");
        await deleteLog(id);
        history.goBack()
    };

    if (loading) return (<LoadingPlaceholder />)

    return (
        <>
            <Paper sx={{ p: 2 }} elevation={4}>
                <Stack direction="row" spacing={1}>
                    <BackButton />
                </Stack>
                <Stack spacing={1} sx={{ mt: 1, mb: 2 }} alignItems="center" justifyContent="center">
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                        {icon()}
                        <Typography variant="h4">{log?.type}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body1">Invoker:</Typography>
                        <Chip variant="outlined" label={log?.invoker} />
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body2">Date:</Typography><Chip variant="outlined" label={date()} />
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 2 }} alignItems="center" justifyContent="center">
                    <Button sx={{ borderRadius: 28 }} color="error" variant="outlined" onClick={handleDelete}>DELETE</Button>
                </Stack>
            </Paper>

            <Paper sx={{ mt: 1, p: 2 }} elevation={4}>
                <Typography variant="h6">Message:</Typography>
                <Divider />
                <pre>{JSON.stringify(log?.message, null, 2)}</pre>
            </Paper>

            <ConfirmDialog
                open={openDialog}
                header="Confirm Delete"
                message="Are you sure you want to delete this log?"
                onConfirm={handleConfirmDelete}
                onCancel={() => setOpenDialog(false)}
            />
        </>
    )
}

export default EditLog