import React, { useState } from 'react';
import { Avatar, CircularProgress, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Stack, Box, Button, TextField } from '@mui/material';
import { UseAuth } from '../../contexts/AuthContext';

const Login = () => {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");
    const [loading, setLoading] = useState(false);

    const { login } = UseAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoginError("");

        try {
            setLoading(true);
            await login(username, password);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
            setLoginError("Login Failed.");
        }
    };

    if (loading) return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}><CircularProgress /></Box>;

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <Stack sx={{ width: 400 }} alignContent="center" alignItems="center" spacing={1}>
                <Avatar><LockOutlinedIcon /></Avatar>
                <Typography component="h1" variant="h5">Log in</Typography>
                <TextField InputProps={{ style: { borderRadius: 28, } }} variant="outlined" margin="normal" required fullWidth id="user" label="User e-mail" name="user" autoComplete="email" autoFocus onChange={e => setUserName(e.target.value)} />
                <TextField InputProps={{ style: { borderRadius: 28, } }} variant="outlined" margin="normal" required fullWidth name="pass" label="Password" type="password" id="pass" autoComplete="current-password" onChange={e => setPassword(e.target.value)} />
                <Typography align="center" color="error">{loginError}</Typography>
                <Button sx={{ mt: 2, borderRadius: 28 }} onClick={handleSubmit} type="submit" fullWidth variant="contained" color="primary">Sign In</Button>
            </Stack>
        </Box>
    );
};

export default Login;
