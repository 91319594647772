import { useHistory } from 'react-router-dom';
import { Typography, Paper, Stack, Button } from '@mui/material'
import RestorePageIcon from '@mui/icons-material/RestorePage';
import { listRepositories } from '../../../apiConnector';
import BackButton from '../../items/BackButton';
import LogsList from '../../items/LogsList';

const Logs = () => {
    const history = useHistory();

    const handleListRequest = async (e) => { await listRepositories(); }
    const handleOpen = (id) => { history.push(`/admin/logs/edit/${id}`); };

    return (
        <>
            <Paper sx={{ p: 2 }} elevation={4}>
                <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                    <Button sx={{ borderRadius: 28 }} startIcon={<RestorePageIcon />} color="primary" variant="outlined" onClick={handleListRequest}>LIST PROJECTS</Button>
                </Stack>

                <Stack direction="row" spacing={2}>
                    <Typography variant="body2">Listing projects takes some time, refresh logs after 3-5 min after listing.</Typography>
                </Stack>
            </Paper>
            <LogsList handleOpen={handleOpen} />
        </>
    )
}

export default Logs