import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, Paper, Stack } from '@mui/material';
import { eventManager } from '../../../eventManager';
import { useError } from '../../contexts/ErrorContext';
import { createGroup, getUser } from '../../../apiConnector';
import { useNotification } from '../../contexts/NotificationContext';
import BackButton from '../../items/BackButton';
import SaveButton from '../../items/SaveButton';
import ItemsSelect from '../../items/ItemsSelect';

const CreateGroup = () => {
    const [name, setName] = useState("");
    const [usersSelected, setUsersSelected] = useState([]);
    const history = useHistory();
    const { showError, hasError } = useError();
    const { addNotification } = useNotification();

    useEffect(() => {
        const handleError = (error) => { showError(error); };
        eventManager.subscribe('api-error', handleError);
        return () => { eventManager.unsubscribe('api-error', handleError); };
    }, []);

    const handleChangeName = (e) => { setName(e.target.value); };

    const activeChanges = useMemo(() => {
        return !name || name === "";
    }, [name]);

    const handleCreate = async (e) => {
        const data = { name, users: usersSelected };

        await createGroup(data);

        if (hasError.current) return;

        addNotification("Group Added!");
        history.goBack();
    };

    return (
        <>
            <Paper sx={{ p: 2 }} elevation={4}>
                <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 2 }}>
                    <BackButton />
                    <TextField
                        InputProps={{ style: { borderRadius: 28, } }}
                        size="small"
                        required
                        fullWidth
                        label="Group Name"
                        value={name}
                        onChange={handleChangeName}
                    />
                    <SaveButton active={!activeChanges} onSave={handleCreate} label='CREATE' />
                </Stack>
            </Paper>

            <ItemsSelect dataFetcher={getUser} sx={{ mt: 1 }} onSelect={setUsersSelected} label="Users" />
        </>
    )
}

export default CreateGroup