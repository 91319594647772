import React, { useState, useEffect, useMemo } from 'react';
import { Box, Chip, Paper, Stack, Typography } from '@mui/material';
import FilterItem from './FilterItem';
import LoadingPlaceholder from './LoadingPlaceholder';

const ItemsSelect = ({ onSelect, defaultSelected = [], sx, label = "Items", dataFetcher }) => {
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [displayLimit, setDisplayLimit] = useState(50);
    const [loading, setLoading] = useState(true);

    const selectedSet = useMemo(() => new Set(selected), [selected]);

    const fetchData = async () => {
        const items = await dataFetcher();
        setItems(items.data);
        setFiltered(items.data);

        setSearchQuery("");
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (defaultSelected.length > 0) {
            setSelected(defaultSelected);
        }
    }, [defaultSelected]);

    useEffect(() => {
        applyFilters();
    }, [searchQuery, items]);

    const applyFilters = () => {
        setFiltered(items.filter(item => {
            const name = item.display_name || item.name;
            const email = item.email || '';
            return name.toLowerCase().includes(searchQuery.toLowerCase()) || email.toLowerCase().includes(searchQuery.toLowerCase());
        }));
    };

    const handleToggleSelect = (item) => {
        const selection = selected.includes(item.id)
            ? selected.filter(id => id !== item.id)
            : [...selected, item.id];
        setSelected(selection);
        onSelect(selection);
    };

    const handleSearch = (query) => {
        setSearchQuery(query.toLowerCase().replace(/_/g, " "));
    };

    const showMore = () => {
        setDisplayLimit(currentLimit => currentLimit + 10);
    };

    if (loading) { return <LoadingPlaceholder />; }

    return (
        <Paper sx={{ p: 2, ...sx }} elevation={4}>
            <Stack direction="row" spacing={1} sx={{ mt: 1, mb: 4 }}>
                <Typography variant="h5">{label}</Typography>
                <FilterItem onSearch={handleSearch} />
            </Stack>
            <Box sx={{ mt: 1 }}>
                {filtered.slice(0, displayLimit).map((item) => (
                    <Chip
                        sx={{ p: 0.2, m: 0.2 }}
                        key={item.id}
                        label={item.display_name || item.name}
                        variant={selectedSet.has(item.id) ? "contained" : "outlined"}
                        color={selectedSet.has(item.id) ? "primary" : "default"}
                        onClick={() => handleToggleSelect(item)}
                    />
                ))}
                {displayLimit < filtered.length && (
                    <Chip variant="outlined" color="default" onClick={showMore} sx={{ m: 0.2, borderRadius: 28 }} label="..." />
                )}
            </Box>
        </Paper>
    );
}

export default ItemsSelect;
