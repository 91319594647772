import React, { useContext, useState, useEffect } from 'react';
import { auth, loginUser } from '../../firebase';
import { setDefaultHeaders, getUser } from '../../apiConnector';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const AuthContext = React.createContext();

export function UseAuth() { return useContext(AuthContext); }

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [pending, setPending] = useState(true);
    const history = useHistory();

    const login = (email, pass) => loginUser(email, pass);
    const logout = () => auth.signOut();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const token = await user.getIdToken(true);
                setDefaultHeaders(token);
                const userData = await getUser(user.uid);
                setUser({ id: user.uid, ...userData.data });
                history.push("/");
            } else {
                setUser(null);
                history.push("/login");
            }
            setPending(false);
        });

        return unsubscribe;
    }, [history]);

    if (pending) return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}><CircularProgress /></Box>;

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}
