import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Paper, Stack, Typography, Divider, FormControlLabel, Switch } from '@mui/material';
import ProjectTile from '../../items/ProjectTile';
import FilterItem from '../../items/FilterItem';
import LoadingPlaceholder from '../../items/LoadingPlaceholder';
import { getUserProjects } from '../../../apiConnector';
import { UseAuth } from '../../contexts/AuthContext';

const Projects = () => {
    const [loaded, setLoaded] = useState(false);
    const [projects, setProjects] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [nameFilter, setNameFilter] = useState(["imme", "imga", "imre"]);
    const { user } = UseAuth();

    useEffect(() => {
        async function fetchData() {
            const resp = await getUserProjects(user.id);
            setProjects(resp.data);
            setFiltered(resp.data);
            setLoaded(true);
        }
        fetchData();
    }, [user.id]);

    useEffect(() => {
        applyFilters();
    }, [nameFilter, searchQuery]);

    const handleSearch = (v) => {
        const query = v.toLowerCase().replaceAll("_", " ");
        setSearchQuery(query);
    };

    const toggleNameFilter = (filterName) => (event) => {
        setNameFilter(prev => {
            const newFilters = event.target.checked ? [...prev, filterName] : prev.filter(name => name !== filterName);
            return newFilters;
        });
    };

    const applyFilters = () => {
        setFiltered(projects.filter(project => {
            const projectNameLower = project.name.toLowerCase();
            return projectNameLower.includes(searchQuery) &&
                nameFilter.some(name => projectNameLower.includes(name));
        }));
    }

    const groupedProjects = useMemo(() => {
        return filtered.reduce((accumulator, project) => {
            const firstLetter = project.name[5].toUpperCase();
            if (!accumulator[firstLetter]) {
                accumulator[firstLetter] = [];
            }
            accumulator[firstLetter].push(project);
            return accumulator;
        }, {});
    }, [filtered]);

    if (!loaded) { return <LoadingPlaceholder />; }
    if (projects.length === 0) { return <Paper sx={{ p: 1 }}>No projects found.</Paper>; }

    return (
        <>
            <Paper sx={{ p: 2 }} elevation={4}>
                <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 1 }}>
                    <FilterItem onSearch={handleSearch} />
                    <FormControlLabel control={<Switch defaultChecked onChange={toggleNameFilter('imme')} />} label="Immersion" />
                    <FormControlLabel control={<Switch defaultChecked onChange={toggleNameFilter('imga')} />} label="Immersion Games" />
                    <FormControlLabel control={<Switch defaultChecked onChange={toggleNameFilter('imre')} />} label="Real Estate" />
                </Stack>
            </Paper>
            <Paper sx={{ mt: 1, p: 2 }} elevation={4}>
                {Object.keys(groupedProjects).sort().map((letter) => (
                    <React.Fragment key={letter}>
                        <Typography variant="h6" sx={{ mt: 4 }}>
                            {letter}
                        </Typography>
                        <Divider />
                        <Grid container spacing={1} sx={{ mt: 1 }}>
                            {groupedProjects[letter].map((project) => (
                                <Grid key={project.id} item>
                                    <ProjectTile ProjectData={project} />
                                </Grid>
                            ))}
                        </Grid>
                    </React.Fragment>
                ))}
            </Paper>
        </>
    );
};

export default Projects;
