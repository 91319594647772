import React, { useState, useEffect, useMemo } from 'react';
import { TextField, FormLabel, FormControlLabel, Switch, Stack, FormControl, RadioGroup, Radio } from '@mui/material/';

const UserTypeSelect = ({ type, onSelect }) => {

    const handleChangeType = (event) => { onSelect([event.target.value]); };

    return (
        <>
            <FormControl>
                <FormLabel>Type</FormLabel>
                <RadioGroup value={type} onChange={handleChangeType} row>
                    <FormControlLabel value="user" control={<Radio />} label="User" />
                    <FormControlLabel value="service" control={<Radio />} label="Service" />
                </RadioGroup>
            </FormControl>
        </>
    )
}

export default UserTypeSelect
