import React from 'react';
import { Typography, Button, Toolbar, Box, AppBar, Stack, Avatar } from '@mui/material';
import logo from '../static/imm_logo.png';
import { UseAuth } from './contexts/AuthContext';

const TopBar = () => {
    const { user, logout } = UseAuth();

    return (
        <AppBar position="static" sx={{ backgroundColor: '#212F3C' }}>
            <Toolbar>
                <Box display="flex" alignItems="center" flexGrow={1}>
                    <Stack direction="row" spacing={2} alignItems="center" flexGrow={1}>
                        <Avatar variant="square" alt="logo" src={logo} sx={{ width: 32, height: 32 }} />
                        <Typography sx={{ color: '#FFFFFF80' }} variant="body2">v 1.0.2</Typography>
                    </Stack>
                    <Stack direction="row" spacing={4} alignItems="center" flexGrow={1}>
                        <Typography variant="h6">Immersion Dashboard</Typography>
                    </Stack>
                    <Stack direction="row" spacing={4} alignItems="center" flexGrow={0}>
                        <Typography sx={{ color: '#FFFFFF80' }} variant="body2">Logged in as:</Typography>
                        <Typography variant="body2">{user?.email || ""}</Typography>
                        <Button variant="outlined" sx={{ borderRadius: 28, color: '#FFFFFF80', borderColor: '#FFFFFF80', }} onClick={logout}>Logout</Button>
                    </Stack>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
