import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { UseAuth } from './contexts/AuthContext'
import TopBar from './TopBar'
import Sidebar from './Sidebar';
import { Grid } from '@mui/material';

export default function AdminRoute({ component: Component, ...rest }) {

    const { user } = UseAuth();

    return (
        <Route
            {...rest}
            render={props => {
                return user && user.roles.includes("admin") ?
                    <>
                        <TopBar />
                        <Grid container>
                            <Grid sx={{ mt: 1, ml: 1 }} item><Sidebar /></Grid>
                            <Grid xs={10} sx={{ m: 1 }} item><Component {...props} /></Grid>
                        </Grid>
                    </>
                    :
                    <Redirect to="/" />
            }}
        >
        </Route>
    )
}