import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const ConfirmDialog = ({ open, header, message, onConfirm, onCancel }) => {

    const handleConfirm = () => { if (onConfirm) onConfirm(); };
    const handleCancel = () => { if (onCancel) onCancel(); };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions  sx={{ mb: 1, mr: 2 }} >
                <Button sx={{ borderRadius: 28 }} variant="contained" color="primary" onClick={handleConfirm}>CONFIRM</Button>
                <Button sx={{ borderRadius: 28 }} variant="contained" color="secondary" onClick={handleCancel}>CANCEL</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
