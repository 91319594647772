import React, { useState, useEffect, useMemo } from 'react';
import { blue, orange } from '@mui/material/colors';
import { UseAuth } from '../../contexts/AuthContext';
import { ListItem, ListItemText, List, ListItemAvatar, Avatar, Typography, Stack, Paper, TextField, Button } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNotification } from '../../contexts/NotificationContext';
import SaveButton from '../../items/SaveButton';
import { getUser, setUser } from '../../../apiConnector';
import LoadingPlaceholder from '../../items/LoadingPlaceholder';

const Info = () => {
    const [userData, setUserData] = useState();
    const [displayName, setDisplayName] = useState("");
    const [loading, setLoading] = useState(true);

    const { user } = UseAuth();
    const { addNotification } = useNotification();

    const fetchData = async () => {
        const data = (await getUser(user.id)).data;
        setUserData(data);
        setDisplayName(data.display_name);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [user]);

    const handleSave = async () => {
        await setUser(user.id, { display_name: displayName });
        await fetchData();
    };

    const handleChangeName = (e) => {
        setDisplayName(e.target.value);
    }

    const activeChanges = useMemo(() => {
        return displayName !== userData?.display_name;
    }, [userData, displayName]);

    if (loading) { return <LoadingPlaceholder />; }

    return (
        <>
            <Paper sx={{ p: 2 }} elevation={4}>
                <Stack direction="row" spacing={1}>
                    <TextField
                        InputProps={{ style: { borderRadius: 28, } }}
                        size="small"
                        required
                        fullWidth
                        label="Display Name"
                        value={displayName}
                        onChange={handleChangeName}
                    />
                    <SaveButton active={activeChanges} onSave={handleSave} label='SAVE' />
                </Stack>

            </Paper>
            <Paper sx={{ mt: 1, p: 2 }}>

                <List>
                    <ListItem divider>SVN Credentials:</ListItem>

                    <ListItem>
                        <ListItemAvatar><Avatar sx={{ bgcolor: blue[500] }}><PersonIcon /></Avatar></ListItemAvatar>
                        <ListItemText primary={<Typography variant="h6" style={{ color: "black", fontWeight: 600 }}>
                            {user.svnuser}
                            <CopyToClipboard onCopy={() => addNotification("User Name Copied!")} text={user.svnuser}>
                                <Button sx={{ ml: 2, borderRadius: 28 }} variant="outlined" startIcon={<ContentCopyIcon />}>Copy</Button>
                            </CopyToClipboard></Typography>} secondary="SVN Username" />
                    </ListItem>

                    <ListItem>
                        <ListItemAvatar><Avatar sx={{ bgcolor: orange[500] }}><LockIcon /></Avatar></ListItemAvatar>
                        <ListItemText primary={<Typography variant="h6" style={{ color: "black", fontWeight: 600 }}>
                            {user.svnpass}
                            <CopyToClipboard onCopy={() => addNotification("User Password Copied!")} text={user.svnpass}>
                                <Button sx={{ ml: 2, borderRadius: 28 }} variant="outlined" startIcon={<ContentCopyIcon />}>Copy</Button>
                            </CopyToClipboard></Typography>} secondary="SVN Password" />
                    </ListItem>
                </List>
            </Paper>
        </>
    )
}

export default Info
