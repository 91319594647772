import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Button, Stack, FormControlLabel, Switch } from '@mui/material';
import FilterItem from '../../items/FilterItem';
import { getProject } from '../../../apiConnector';
import LoadingPlaceholder from '../../items/LoadingPlaceholder';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [nameFilter, setNameFilter] = useState(["imme", "imga", "imre"]);
    const [stateFilter, setStateFilter] = useState(["ACTIVE", "ARCHIVED"]);
    const [loading, setLoading] = useState(true);

    const history = useHistory();

    const fetchData = async () => {
        const projects = await getProject();
        setProjects(projects.data);
        setFiltered(projects.data);
        setLoading(false);
    };

    useEffect(() => {
        applyFilters();
    }, [nameFilter, stateFilter, searchQuery]);

    useEffect(() => {
        fetchData();
    }, []);

    const applyFilters = () => {
        setFiltered(projects.filter(project => {
            const projectNameLower = project.name.toLowerCase();
            const projectState = project.state;
            return projectNameLower.includes(searchQuery) && nameFilter.some(name => projectNameLower.includes(name)) && stateFilter.some(state => projectState.includes(state));
        }));
    }

    const icon = (state) => {
        switch (state) {
            case "ACTIVE": return <Chip label={state} color="success" variant="contained" />;
            case "ERROR": return <Chip label={state} color="error" variant="contained" />;
            case "ARCHIVED": return <Chip label={state} variant="outlined" />;
            default: return <Chip label={state} variant="outlined" icon={<CircularProgress color='primary' size={20} />} />;
        }
    }

    const handleCreateProject = () => { history.push('/admin/projects/create'); };

    const handleSearch = (v) => {
        const query = v.toLowerCase().replaceAll("_", " ");
        setSearchQuery(query);
    };

    const toggleNameFilter = (filterName) => (event) => {
        setNameFilter(prev => {
            const newFilters = event.target.checked ? [...prev, filterName] : prev.filter(name => name !== filterName);
            return newFilters;
        });
    };

    const toggleStateFilter = (filterName) => (event) => {
        setStateFilter(prev => {
            const newFilters = event.target.checked ? [...prev, filterName] : prev.filter(name => name !== filterName);
            return newFilters;
        });
    };

    const handleRefresh = () => { fetchData(); }
    const handleEdit = (id) => { history.push(`/admin/projects/edit/${id}`); }

    if (loading) { return <LoadingPlaceholder />; }

    return (
        <>
            <Paper sx={{ p: 2 }} elevation={4}>
                <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 2 }}>
                    <Button sx={{ borderRadius: 28 }} startIcon={<AddIcon />} color="success" variant="contained" onClick={handleCreateProject}>CREATE PROJECT</Button>
                    <Button sx={{ borderRadius: 28 }} startIcon={<RefreshIcon />} color="primary" variant="outlined" onClick={handleRefresh}>REFRESH</Button>
                    <FilterItem onSearch={handleSearch}></FilterItem>
                </Stack>

                <Stack direction="row" spacing={2}>
                    <FormControlLabel control={<Switch defaultChecked onChange={toggleNameFilter('imme')} />} label="Immersion" />
                    <FormControlLabel control={<Switch defaultChecked onChange={toggleNameFilter('imga')} />} label="Immersion Games" />
                    <FormControlLabel control={<Switch defaultChecked onChange={toggleNameFilter('imre')} />} label="Real Estate" />
                    <FormControlLabel control={<Switch defaultChecked onChange={toggleStateFilter('ACTIVE')} />} label="Show Active" />
                    <FormControlLabel control={<Switch defaultChecked onChange={toggleStateFilter('ARCHIVED')} />} label="Show Archived" />
                </Stack>
            </Paper>

            <Paper sx={{ mt: 1, p: 2 }} elevation={4}>
                <Typography sx={{ p: 2 }} variant="h6">Showing {filtered.length} / {projects.length} projects</Typography>
                <TableContainer>
                    <Table size="small" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">State</TableCell>
                                <TableCell align="right">Groups</TableCell>
                                <TableCell align="right">Users</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filtered?.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row"><Typography>{row.name}</Typography></TableCell >
                                    <TableCell align="right">{icon(row.state)}</TableCell >
                                    <TableCell align="right"><Chip variant="outlined" label={row.groups?.length ?? 0} /></TableCell >
                                    <TableCell align="right">
                                        <Chip color="primary" variant="outlined" label={row.users?.length ?? 0} />
                                    </TableCell >
                                    <TableCell align="right">
                                        {row.state !== "ACTIVE" && row.state !== "ARCHIVED" ?
                                            <></>
                                            :
                                            <Button sx={{ borderRadius: 28 }} startIcon={<EditIcon />} size="small" color="primary" variant="contained" onClick={() => handleEdit(row.id)}>EDIT</Button>}
                                    </TableCell>
                                </TableRow >
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
};

export default Projects;
