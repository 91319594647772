import axios from 'axios';
import { auth } from './firebase';
import { eventManager } from './eventManager';

const DEV_MODE = process.env.NODE_ENV === 'development';
const APIBASEURL = DEV_MODE ? 'http://localhost:5001/immersion-dashboard-dev/europe-central2' : 'https://europe-central2-immersion-dashboard-dev.cloudfunctions.net'

const APIS = {
    API: `${APIBASEURL}/app`
}

////////////////////

const apiClient = axios.create({ baseURL: APIS.API, });

apiClient.interceptors.request.use(
    async (config) => {
        return config;
    }, (e) => { return Promise.reject(e); }
);

apiClient.interceptors.response.use(
    response => response,
    async (error) => {
        const req = error.config;

        // token refresh if 401, catch generc errors
        if (error.response.status === 401 && !req._retry) {
            req._retry = true;
            console.log("Refreshing token...");
            const user = auth.currentUser;
            if (user) {
                const token = await user.getIdToken(true);
                setDefaultHeaders(token);
                req.headers['Authorization'] = `Bearer ${token}`;
                return apiClient(req);
            }
        } else if (!error.response || error.response.status >= 400) { eventManager.publish('api-error', error); }

        return Promise.reject(error);
    }
);

// this is called in AuthContext after user login and when encoutered 401 from the backend

export const setDefaultHeaders = (token) => {
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        // 'API-Auth': userJWTToken
    }
    apiClient.defaults.headers.common = { ...apiClient.defaults.headers.common, ...headers };
};

////////////////////

// users

export const createUser = async (data) => await apiClient.put(`/users`, { data }).catch(e => e);
export const getUser = async (id = "") => await apiClient.get(`/users${Array.isArray(id) ? `?ids=${id.join(',')}` : id ? `/${id}` : ''}`).catch(e => e);
export const setUser = async (id, data) => { try { return await apiClient.post(`/users/${id}`, { data }); } catch (e) { return e; }; };
export const deleteUser = async (id = "") => { try { return await apiClient.delete(`/users/${id}`); } catch (e) { return e; }; };

export const getUserProjects = async (id = "") => { try { return await apiClient.get(`/users/${id}/projects`); } catch (e) { return e; }; };
export const getUserGroups = async (id = "") => { try { return await apiClient.get(`/users/${id}/groups`); } catch (e) { return e; }; };

export const inviteUser = async (id = "", reset) => { try { return await apiClient.get(`/users/${id}/invite?reset=${reset}`); } catch (e) { return e; }; };

// access

export const getAccess = async (ip, email) => { try { return await apiClient.get(`/access?ip=${ip}${email ? `&email=${email}` : ''}`); } catch (e) { return e; }; };
export const setAccess = async (data) => { try { return await apiClient.post(`/access`, { data }); } catch (e) { return e; }; };
export const deleteAccess = async (email) => { try { return await apiClient.delete(`/access?email=${email}`); } catch (e) { return e; }; };
// export const syncAccess = async (id, data) => { try { return await apiClient.post(`/access/${id}/sync`, { data }); } catch (e) { return e; }; };

// groups

export const createGroup = async (data) => { try { return await apiClient.put(`/groups`, { data }); } catch (e) { return e; }; };
export const getGroup = async (id = "") => await apiClient.get(`/groups${Array.isArray(id) ? `?ids=${id.join(',')}` : id ? `/${id}` : ''}`).catch(e => e);
export const setGroup = async (id, data) => { try { return await apiClient.post(`/groups/${id}`, { data }); } catch (e) { return e; }; };
export const deleteGroup = async (id = "") => { try { return await apiClient.delete(`/groups/${id}`); } catch (e) { return e; }; };

// projects

export const createProject = async (data) => { try { return await apiClient.put(`/projects`, { data }); } catch (e) { return e; }; };
export const getProject = async (id = "") => { try { return await apiClient.get(`/projects/${id}`); } catch (e) { return e; }; };
export const setProject = async (id, data) => { try { return await apiClient.post(`/projects/${id}`, { data }); } catch (e) { return e; }; };
export const deleteProject = async (id = "") => { try { return await apiClient.delete(`/projects/${id}`); } catch (e) { return e; }; };

export const getProjectState = async (id = "") => { try { return await apiClient.get(`/projects/${id}/state`); } catch (e) { return e; }; };
export const setProjectState = async (id = "", state) => { try { return await apiClient.post(`/projects/${id}/state`, state); } catch (e) { return e; }; };

// repositories

export const listRepositories = async () => { try { return await apiClient.get(`/repositories/log/list`); } catch (e) { return e; }; };
export const updateRepositoriesAccess = async () => { try { return await apiClient.get(`/repositories/access/update`); } catch (e) { return e; }; };
export const createRepository = async (id = "") => { try { return await apiClient.put(`/repositories/${id}`); } catch (e) { return e; }; };
export const archiveRepository = async (id = "") => { try { return await apiClient.delete(`/repositories/${id}`); } catch (e) { return e; }; };
export const restoreRepository = async (id = "") => await apiClient.get(`/repositories/${id}/restore`).catch(e => e);

// log

export const getLog = async (id = "") => await apiClient.get(`/log/${id}`).catch(e => e);
export const getLogs = async (page = 1, limit = 50) => await apiClient.get(`/log?page=${page}&limit=${limit}`).catch(e => e);
export const getLogsCount = async () => await apiClient.get(`/log/count`).catch(e => e);
export const deleteLog = async (id = "") => await apiClient.delete(`/log/${id}`).catch(e => e);