import Dashboard from './components/pages/common/Dashboard';
import CreateUser from './components/pages/admin/CreateUser';
import EditUser from './components/pages/admin/EditUser';
import CreateProject from './components/pages/admin/CreateProject';
import EditProject from './components/pages/admin/EditProject';
import CreateGroup from './components/pages/admin/CreateGroup';
import EditGroup from './components/pages/admin/EditGroup';
import EditLog from './components/pages/admin/EditLog';
import Info from './components/pages/common/Info'
import Projects from './components/pages/common/Projects';
import AdminUsers from './components/pages/admin/Users';
import AdminGroups from './components/pages/admin/Groups';
import AdminProjects from './components/pages/admin/Projects';
import AdminLogs from './components/pages/admin/Logs';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from "@mui/material/styles";

import Login from './components/pages/common/Login';
import { AuthProvider } from './components/contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';

import { ErrorProvider } from './components/contexts/ErrorContext';
import { NotificationProvider } from './components/contexts/NotificationContext';

const theme = createTheme();

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <ErrorProvider>
            <NotificationProvider>
              <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute exact path="/info" component={Info} />
                <PrivateRoute exact path="/projects" component={Projects} />

                <AdminRoute exact path="/admin/users" component={AdminUsers} />
                <AdminRoute exact path="/admin/groups" component={AdminGroups} />
                <AdminRoute exact path="/admin/projects" component={AdminProjects} />
                <AdminRoute exact path="/admin/logs" component={AdminLogs} />

                <AdminRoute exact path="/admin/users/create" component={CreateUser} />
                <AdminRoute exact path="/admin/users/edit/:id" component={EditUser} />

                <AdminRoute exact path="/admin/projects/create" component={CreateProject} />
                <AdminRoute exact path="/admin/projects/edit/:id" component={EditProject} />

                <AdminRoute exact path="/admin/groups/create" component={CreateGroup} />
                <AdminRoute exact path="/admin/groups/edit/:id" component={EditGroup} />

                <AdminRoute exact path="/admin/logs/edit/:id" component={EditLog} />

                <Route exact path="/login" component={Login} />
              </Switch>
            </NotificationProvider>
          </ErrorProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;