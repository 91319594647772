import { Link, useLocation } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material/';
import { Apps, Info, Home } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ChatIcon from '@mui/icons-material/Chat';

import { UseAuth } from './contexts/AuthContext';

const menuItems = [
    { key: 'Home', to: '/', icon: <Home />, label: 'Home', section: 'user' },
    { key: 'Projects', to: '/projects', icon: <Apps />, label: 'Projects', section: 'user' },
    { key: 'User Info', to: '/info', icon: <Info />, label: 'User Info', section: 'user' },

    { key: 'Admin Users', to: '/admin/users', icon: <AccountCircleIcon />, label: 'Users', section: 'admin' },
    { key: 'Admin Groups', to: '/admin/groups', icon: <GroupIcon />, label: 'Groups', section: 'admin' },
    { key: 'Admin Projects', to: '/admin/projects', icon: <AccountTreeIcon />, label: 'Projects', section: 'admin' },
    { key: 'Admin Logs', to: '/admin/logs', icon: <ChatIcon />, label: 'Logs', section: 'admin' },
];

function Sidebar() {
    const { user } = UseAuth();
    const location = useLocation();

    const getItemStyle = (path) => location.pathname === path ? { color: 'primary.main' } : {};
    const getTextStyle = (path) => location.pathname === path ? { color: 'primary.main', fontWeight: 'bold' } : {};

    const renderMenuItems = (section) => {
        return menuItems.filter(item => item.section === section).map(({ key, to, icon, label }) => (
            <ListItem divider button component={Link} key={key} to={to}>
                <ListItemIcon sx={getItemStyle(to)}>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={
                    <Typography sx={getTextStyle(to)}>
                        {label}
                    </Typography>
                } />
            </ListItem>
        ));
    };

    return (
        <Paper sx={{ p: 2 }} elevation={4}>
            <List sx={{ width: 200 }}>
                {renderMenuItems('user')}
                {user?.roles?.includes('admin') && (
                    <>
                        <Typography variant="subtitle2" sx={{ mt: 4, p: 1 }}>
                            Admin
                        </Typography>
                        {renderMenuItems('admin')}
                    </>
                )}
            </List>
        </Paper>
    );
}

export default Sidebar;
