import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Chip, Typography, Paper, Stack, Button, TableContainer, Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import LoadingPlaceholder from '../../items/LoadingPlaceholder';
import { getGroup, getUser } from '../../../apiConnector';
import FilterItem from '../../items/FilterItem';

const Groups = () => {
    const [allUsers, setAllUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);

    const history = useHistory();

    const fetchData = async () => {
        const groups = await getGroup();
        setGroups(groups.data);
        setFiltered(groups.data);

        const aUsers = await getUser();
        setAllUsers(aUsers.data);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        applyFilters();
    }, [searchQuery, groups]);

    const applyFilters = () => {
        setFiltered(groups.filter(group =>
            group.name.toLowerCase().includes(searchQuery)
        ));
    };

    const handleSearch = (value) => { setSearchQuery(value.toLowerCase().replace(/_/g, " ")); };

    const handleCreateGroup = () => { history.push('/admin/groups/create'); };
    const handleEdit = (id) => { history.push(`/admin/groups/edit/${id}`); };
    const handleEditUser = (id) => { history.push(`/admin/users/edit/${id}`); };

    const getUserAvatar = (id) => {
        const user = allUsers.find(u => id === u.id);
        return (
            <Chip
                sx={{ m: 0.2 }}
                label={user?.display_name || 'Unknown'}
                variant="outlined"
                size="small"
                onClick={() => handleEditUser(id)}
            />
        );
    };

    if (loading) { return <LoadingPlaceholder />; }

    return (
        <>
            <Paper sx={{ p: 2 }} elevation={4}>
                <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 2 }}>
                    <Button
                        sx={{ borderRadius: 28 }}
                        startIcon={<AddIcon />}
                        color="success"
                        variant="contained"
                        onClick={handleCreateGroup}
                    >
                        ADD GROUP
                    </Button>
                    <FilterItem onSearch={handleSearch} />
                </Stack>
            </Paper>

            <Paper sx={{ mt: 1, p: 2 }} elevation={4}>
                <Typography sx={{ p: 2 }} variant="h6">
                    Showing {filtered.length} / {groups.length} groups
                </Typography>
                <TableContainer>
                    <Table size="small" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Users</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filtered.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        <Stack direction="row" spacing={1}>
                                            <Typography>{row.name}</Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        {row.users?.slice().sort().map((user) => getUserAvatar(user))}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button
                                            sx={{ borderRadius: 28 }}
                                            startIcon={<EditIcon />}
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            onClick={() => handleEdit(row.id)}
                                        >
                                            EDIT
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
};

export default Groups;
