import AccessTile from '../../items/AccessTile'
import { UseAuth } from '../../contexts/AuthContext'
import { Grid } from '@mui/material'

const Dashboard = () => {
    const { user } = UseAuth();

    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item><AccessTile Data={user} /></Grid>
      </Grid>
    )
}

export default Dashboard;