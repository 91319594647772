import { FormLabel, FormControlLabel, FormControl, RadioGroup, Radio } from '@mui/material/';

const ProjectTypeSelect = ({ type, onSelect }) => {

    const handleChangeType = (event) => { onSelect([event.target.value]); };

    return (
        <>
            <FormControl>
                <FormLabel>Type</FormLabel>
                <RadioGroup value={type} onChange={handleChangeType} row>
                    <FormControlLabel value="imme" control={<Radio />} label="General" />
                    <FormControlLabel value="imga" control={<Radio />} label="Games" />
                    <FormControlLabel value="imre" control={<Radio />} label="Real Estate" />
                </RadioGroup>
            </FormControl>
        </>
    )
}

export default ProjectTypeSelect;