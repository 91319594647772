import React, { useState, useEffect } from 'react';
import { Paper, Stack, Button, Select, MenuItem, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, ButtonGroup } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import InfoIcon from '@mui/icons-material/Info';
import ReportIcon from '@mui/icons-material/Report';
import { getLogs, getLogsCount } from '../../apiConnector';
import LoadingPlaceholder from './LoadingPlaceholder';

const formatDate = (log) => {
    if (!log?.timeStamp) return '-';
    const milliseconds = log.timeStamp._seconds * 1000 + log.timeStamp._nanoseconds / 1000000;
    const date = new Date(milliseconds);
    return date.toLocaleString('pl-PL');
};

const severityIcon = (severity) => {
    const colorMap = {
        "INFO": "royalblue",
        "ERROR": "red"
    };
    const Icon = severity === "INFO" ? InfoIcon : ReportIcon;
    return <Icon style={{ color: colorMap[severity] }} />;
};

const PaginationInfo = ({ currentPage, pageSize, totalCount }) => {
    const firstIndex = currentPage * pageSize + 1;
    const lastIndex = Math.min((currentPage + 1) * pageSize, totalCount);
    return (
        <Typography variant="body2" >{`Showing ${firstIndex}-${lastIndex} of ${totalCount}`}</Typography>
    );
};

const LogsList = ({ handleOpen }) => {
    const [logs, setLogs] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const logData = (await getLogs(page + 1, pageSize)).data;
            const logCount = (await getLogsCount()).data;
            setLogs(logData);
            setCount(logCount);
        };
        fetchData();
    }, [refresh, page, pageSize]);

    const handleRefreshLog = () => {
        setRefresh(!refresh);
    };

    if (!logs || logs.length <= 0) return <LoadingPlaceholder />;

    return (
        <Paper sx={{ mt: 1, p: 2 }} elevation={4}>
            <Stack sx={{ mb: 1 }} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Button variant="outlined" sx={{ borderRadius: 28 }} startIcon={<SyncIcon />} onClick={handleRefreshLog}>REFRESH</Button>
                <PaginationInfo currentPage={page} pageSize={pageSize} totalCount={count} />
                <Stack direction="row" alignItems="center" spacing={1}>
                    <ButtonGroup>
                        <Button variant="outlined" onClick={() => setPage(prev => Math.max(prev - 1, 0))} disabled={page === 0}>Prev</Button>
                        <Select size="small" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                        <Button variant="outlined" onClick={() => setPage(prev => prev + 1)} disabled={page >= Math.ceil(count / pageSize) - 1}>Next</Button>
                    </ButtonGroup>
                </Stack>
            </Stack>

            <TableContainer>
                <Table size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Invoker</TableCell>
                            <TableCell align="center">Severity</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Message</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logs.map((log, index) => (
                            <TableRow key={log.id}>
                                <TableCell>{index + 1 + pageSize * page}</TableCell>
                                <TableCell>{formatDate(log)}</TableCell>
                                <TableCell>{log.invoker}</TableCell>
                                <TableCell align="center">{severityIcon(log.severity)}</TableCell>
                                <TableCell>{log.type}</TableCell>
                                <TableCell>
                                    {JSON.stringify(log?.message).length <= 60 ? JSON.stringify(log?.message) : JSON.stringify(log?.message).slice(0, 60) + " ..."}
                                </TableCell>
                                <TableCell align="right"><Button sx={{ borderRadius: 28 }} variant="contained" size="small" onClick={() => handleOpen(log.id)}>OPEN</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default LogsList;
