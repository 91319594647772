import { Stack, Skeleton } from '@mui/material';

const LoadingPlaceholder = () => {
  return (
    <Stack sx={{ width: 400, mt: 1, p: 2 }} spacing={1}>
      <Skeleton variant="text" />
      <Skeleton variant="rounded" height={60} />
      <Skeleton variant="rounded" height={60} />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="rounded" height={60} />
    </Stack>
  );
};

export default LoadingPlaceholder;