import React, { useState, useEffect, useMemo } from 'react';
import { eventManager } from '../../../eventManager';
import { useError } from '../../contexts/ErrorContext';
import { TextField, Paper, Divider, Stack } from '@mui/material';
import { createUser, getUser, setAccess, inviteUser } from '../../../apiConnector'
import UserServiceAccessSelect from '../../items/UserServiceAccessSelect';
import UserRoleSelect from '../../items/UserRoleSelect';
import UserTypeSelect from '../../items/UserTypeSelect';
import SaveButton from '../../items/SaveButton';
import { useNotification } from '../../contexts/NotificationContext';
import BackButton from '../../items/BackButton';

const userDefaultData = {
    email: "",
    display_name: "",
    svnuser: "",
    svnpass: "",
    service_access: ["svn", "upackage"],
    roles: ["user"],
    account_type: "user"
}

const CreateUser = () => {
    const [userData, setUserData] = useState(userDefaultData);
    const [email, setEmail] = useState();
    const [role, setRole] = useState(userDefaultData.roles);
    const [type, setType] = useState(userDefaultData.account_type);
    const [services, setServices] = useState(userDefaultData.service_access);

    const { showError, hasError } = useError();
    const { addNotification } = useNotification();

    useEffect(() => {
        const handleError = (error) => { showError(error); };
        eventManager.subscribe('api-error', handleError);
        return () => { eventManager.unsubscribe('api-error', handleError); };
    }, []);

    const handleInvite = async () => {
        const newUser = await createUser(userData);
        if (hasError.current) return;

        const newUserData = await getUser(newUser.data.id);
        await setAccess(newUserData.data);
        await inviteUser(newUser.data.id);

        addNotification("Invite Sent!");

        setUserData(userDefaultData);
        setEmail("");
    };

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
        setUserData({
            ...userData,
            email: e.target.value,
            display_name: getDisplayName(e.target.value)
        });
    }

    const getDisplayName = (email) => {
        var dn = email.split("@")[0].split(".");
        return capitalizeFirstLetter(dn[0]) + " " + capitalizeFirstLetter(dn[1]);
    }

    const capitalizeFirstLetter = (string) => { if (string && string.length > 0) return string.charAt(0).toUpperCase() + string.slice(1); else return "" }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const activeChanges = useMemo(() => {
        return !email || !emailPattern.test(email);
    }, [email]);

    return (
        <>
            <Paper sx={{ p: 2 }} elevation={4}>
                <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 2 }}>
                    <BackButton />
                    <TextField
                        InputProps={{ style: { borderRadius: 28, } }}
                        size="small"
                        required
                        fullWidth
                        label="User Email"
                        autoComplete="email"
                        value={email}
                        onChange={handleChangeEmail}
                    />
                    <SaveButton active={!activeChanges} onSave={handleInvite} label='INVITE' />
                </Stack>
            </Paper>
            <Paper sx={{ mt: 1, p: 2 }} elevation={4}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <UserRoleSelect role={role} onSelect={setRole} />
                    <UserTypeSelect type={type} onSelect={setType} />
                </Stack>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <UserServiceAccessSelect services={services} onSelect={setServices} />
            </Paper>
        </>
    )
}

export default CreateUser