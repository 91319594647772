import { TextField } from '@mui/material/';

const FilterItem = ({ onSearch }) => {

    return (
        <TextField InputProps={{ style: { borderRadius: 28, } }} size="small" label="Search" onChange={e => onSearch(e.target.value)} />
    )
}

export default FilterItem
