import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, Paper, Typography, Divider } from '@mui/material';
import { createRepository } from '../../../apiConnector'
import { createProject, getUser, getGroup } from '../../../apiConnector';
import Stack from '@mui/material/Stack';
import { eventManager } from '../../../eventManager';
import { useError } from '../../contexts/ErrorContext';
import { useNotification } from '../../contexts/NotificationContext';
import BackButton from '../../items/BackButton';
import SaveButton from '../../items/SaveButton';
import ProjectTypeSelect from '../../items/ProjectTypeSelect';
import ItemsSelect from '../../items/ItemsSelect';

const CreateProject = () => {

    const [projectData, setProjectData] = useState(null);
    const [prefix, setPrefix] = useState('imme');
    const [usersSelected, setUsersSelected] = useState([]);
    const [groupsSelected, setGroupsSelected] = useState(["admins"]);
    const [name, setName] = useState('');

    const history = useHistory();
    const { showError, hasError } = useError();
    const { addNotification } = useNotification();

    useEffect(() => {
        const handleError = (error) => { showError(error); };
        eventManager.subscribe('api-error', handleError);
        return () => { eventManager.unsubscribe('api-error', handleError); };
    }, []);

    const activeChanges = useMemo(() => {
        return !name || name === "";
    }, [name]);


    const handleCreate = async (e) => {

        if (!/^[a-zA-Z0-9_]+$/.test(name)) { showError({ response: { data: { message: "Project name has illegal characters! Olny a-z, A-Z, 0-9 and _ (underscore) allowed." } } }); return; }

        await createProject({ ...projectData, state: "INIT" });
        if (hasError.current) return;
        await createRepository(projectData.name);
        if (hasError.current) return;

        addNotification("Project created. Repository will be available shortly.");
        history.goBack();
    };

    const handleChangeName = (e) => {
        const name = e.target.value.replaceAll(" ", "_").toLowerCase()
        setName(name)
        setProjectData({ ...projectData, name: prefix + "_" + name, users: usersSelected, groups: groupsSelected });
    }

    const handleChangePrefix = (e) => {
        const prefix = e;
        setPrefix(prefix)
        setProjectData({ ...projectData, name: prefix + "_" + name, users: usersSelected, groups: groupsSelected });
    }

    const handleChangeUsers = (e) => {
        setUsersSelected(e);
        setProjectData({ ...projectData, users: e });
    }

    const handleChangeGroups = (e) => {
        setGroupsSelected(e);
        setProjectData({ ...projectData, groups: e });
    }

    return (
        <>
            <Paper sx={{ p: 2 }} elevation={4}>
                <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 2 }}>
                    <BackButton />
                    <TextField
                        InputProps={{ style: { borderRadius: 28, } }}
                        size="small"
                        required
                        fullWidth
                        label="Project Name"
                        value={name}
                        onChange={handleChangeName}
                    />
                    <SaveButton active={!activeChanges} onSave={handleCreate} label='CREATE' />
                </Stack>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <ProjectTypeSelect type={prefix} onSelect={handleChangePrefix} />
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Typography variant="subtitle1">Repository Name: {prefix + "_" + name}</Typography>
            </Paper>

            <ItemsSelect dataFetcher={getUser} onSelect={handleChangeUsers} defaultSelected={usersSelected} sx={{ mt: 1 }} label="Users" />
            <ItemsSelect dataFetcher={getGroup} onSelect={handleChangeGroups} defaultSelected={groupsSelected} sx={{ mt: 1 }} label="Groups" />
        </>
    )
}

export default CreateProject