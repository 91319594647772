import React, { createContext, useContext, useState, useCallback } from 'react';
import { Snackbar, Alert } from '@mui/material';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const addNotification = useCallback((message, severity = 'success') => {
        setNotifications((prev) => [...prev, { message, severity, key: new Date().getTime() }]);
    }, []);

    const removeNotification = useCallback((key) => {
        setNotifications((prev) => prev.filter((notification) => notification.key !== key));
    }, []);

    return (
        <NotificationContext.Provider value={{ addNotification }}>
            {children}
            {notifications.map(({ message, severity, key }) => (
                <Snackbar
                    key={key}
                    open
                    autoHideDuration={5000}
                    onClose={() => removeNotification(key)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert severity={severity} variant="filled" onClose={() => removeNotification(key)}>
                        {message}
                    </Alert>
                </Snackbar>
            ))}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => useContext(NotificationContext);
