import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Button, Stack, FormControlLabel, Switch } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FilterItem from '../../items/FilterItem';
import EditIcon from '@mui/icons-material/Edit';
import LoadingPlaceholder from '../../items/LoadingPlaceholder';
import { getUser } from '../../../apiConnector';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [roleFilter, setRoleFilter] = useState(["user", "admin"]);
    const [loading, setLoading] = useState(true);

    const history = useHistory();

    const fetchData = async () => {
        const fetchedUsers = (await getUser()).data;
        setUsers(fetchedUsers);
        setFiltered(fetchedUsers);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        applyFilters();
    }, [roleFilter, searchQuery]);

    const applyFilters = () => {
        setFiltered(users.filter(user =>
            (user.display_name.toLowerCase().includes(searchQuery) ||
                user.email.toLowerCase().includes(searchQuery)) &&
            roleFilter.some(name => user.roles.includes(name))
        ));
    }

    const toggleRoleFilter = (filterName) => (event) => {
        setRoleFilter(prev => {
            const newFilters = event.target.checked ? [...prev, filterName] : prev.filter(name => name !== filterName);
            return newFilters;
        });
    };

    const handleSearch = (v) => {
        const query = v.toLowerCase().replaceAll("_", " ");
        setSearchQuery(query);
    };

    const handleCreateUser = () => history.push('/admin/users/create');
    const handleEdit = (id) => { history.push(`/admin/users/edit/${id}`); }

    if (loading) { return <LoadingPlaceholder />; }

    return (
        <>
            <Paper sx={{ p: 2 }} elevation={4}>
                <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 2 }}>
                    <Button sx={{ borderRadius: 28 }} startIcon={<AddIcon />} color="success" variant="contained" onClick={handleCreateUser}>ADD USER</Button>
                    <FilterItem onSearch={handleSearch}></FilterItem>
                </Stack>

                <Stack direction="row" spacing={2}>
                    <FormControlLabel control={<Switch defaultChecked onChange={toggleRoleFilter('admin')} />} label="Admins" />
                    <FormControlLabel control={<Switch defaultChecked onChange={toggleRoleFilter('user')} />} label="Users (exc Admins)" />
                </Stack>
            </Paper>

            <Paper sx={{ mt: 1, p: 2 }} elevation={4}>
                <Typography sx={{ p: 2 }} variant="h6">Showing {filtered.length} / {users.length} users</Typography>
                <TableContainer>
                    <Table size="small" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Access</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filtered?.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        <Stack direction="row" spacing={1} >
                                            <Typography>{row.display_name}</Typography>
                                            {row.roles.includes("admin") && <Chip size="small" variant="contained" color="warning" label="Admin" />}
                                            {row.account_type === "service" && <Chip size="small" variant="outlined" label={row.account_type} />}

                                        </Stack>
                                    </TableCell >
                                    <TableCell><Typography variant="body2">{row.email}</Typography></TableCell >
                                    <TableCell>
                                        {row.service_access.slice().sort().map((access) => (
                                            <Chip sx={{ m: 0.2 }} key={access} label={access} variant="outlined" size="small" />
                                        ))}
                                    </TableCell >
                                    <TableCell align="right"><Button sx={{ borderRadius: 28 }} startIcon={<EditIcon />} size="small" color="primary" variant="contained" onClick={() => handleEdit(row.id)}>EDIT</Button></TableCell>
                                </TableRow >
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}

export default Users;
