import React, { useState, useEffect, useMemo } from 'react';
import { TextField, FormLabel, FormControlLabel, Switch, Stack, FormControl, RadioGroup, Radio } from '@mui/material/';

const UserRoleSelect = ({ role, onSelect }) => {

    const handleChangeRole = (event) => { onSelect([event.target.value]); };

    return (
        <>
            <FormControl>
                <FormLabel>Role</FormLabel>
                <RadioGroup value={role} onChange={handleChangeRole} row>
                    <FormControlLabel value="user" control={<Radio />} label="User" />
                    <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                </RadioGroup>
            </FormControl>
        </>
    )
}

export default UserRoleSelect