import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Paper, Stack, Typography, Box, Chip, Avatar } from '@mui/material';
import { blue } from '@mui/material/colors';
import { getGroup, setGroup, getUser, deleteGroup, updateRepositoriesAccess } from '../../../apiConnector';
import GroupsIcon from '@mui/icons-material/Groups';
import BackButton from '../../items/BackButton';
import ConfirmDialog from '../../dialogs/ConfirmDialog';
import { useNotification } from '../../contexts/NotificationContext';
import SaveButton from '../../items/SaveButton';
import LoadingPlaceholder from '../../items/LoadingPlaceholder';
import ItemsSelect from '../../items/ItemsSelect';

const EditProject = ({ match }) => {
    const [groupData, setGroupData] = useState(null);
    const [usersSelected, setUsersSelected] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(true);

    const history = useHistory();
    const { addNotification } = useNotification();
    const id = match.params.id;

    const fetchData = async () => {
        const gData = (await getGroup(id)).data;
        const auData = (await getUser()).data;

        if (gData && auData) {
            setGroupData(gData);
            if (gData.users && gData.users.length > 0) {
                setUsersSelected(gData.users.map(user => user));
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [id]);


    const activeChanges = useMemo(() => {
        const projectUserIds = new Set(groupData?.users?.map(user => user));
        const usersSelectedSet = new Set(usersSelected);

        return usersSelectedSet.size !== projectUserIds.size || [...usersSelectedSet].some(id => !projectUserIds.has(id));
    }, [usersSelected, groupData]);

    const handleSave = async () => {
        await setGroup(id, { users: usersSelected });
        await updateRepositoriesAccess();
        await fetchData();
        addNotification("Group Saved!");
    };

    const handleDelete = () => { setOpenDialog(true); };

    const handleConfirmDelete = async () => {
        await deleteGroup(id)
        await updateRepositoriesAccess()
        addNotification("Group Deleted!");
        setOpenDialog(false);
        history.goBack();
    };

    if (loading) { return <LoadingPlaceholder />; }

    return (
        <>
            <Paper sx={{ p: 2 }} elevation={4}>
                <Stack direction="row" spacing={1}>
                    <BackButton />
                </Stack>
                <Stack spacing={1} sx={{ mt: 1, mb: 2 }} alignItems="center" justifyContent="center">
                    <Stack direction="row" spacing={1}>
                        <Avatar sx={{ bgcolor: blue[500] }}><GroupsIcon /></Avatar>
                        <Typography variant="h4">{groupData.name}</Typography>
                    </Stack>
                    {groupData?.protected && (<Typography variant="body2">This group is protected from deletion.</Typography>)}
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 2 }} alignItems="center" justifyContent="center">
                    <SaveButton active={activeChanges} onSave={handleSave} />
                    {!groupData?.protected && (<Button sx={{ borderRadius: 28 }} color="error" variant="outlined" onClick={handleDelete}>DELETE</Button>)}
                </Stack>
            </Paper>

            <ItemsSelect dataFetcher={getUser} onSelect={setUsersSelected} defaultSelected={usersSelected} sx={{ mt: 1 }} label="Users" />

            <ConfirmDialog
                open={openDialog}
                header="Confirm Delete"
                message="Are you sure you want to delete this group?"
                onConfirm={handleConfirmDelete}
                onCancel={() => setOpenDialog(false)}
            />
        </>
    );
};

export default EditProject;
