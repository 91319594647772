import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, Checkbox, Button, Stack, Chip, Divider, Box, Typography, Paper, FormControlLabel, Switch, FormControl, FormLabel, RadioGroup, Radio, Snackbar, Alert } from '@mui/material'
import Accordion from '@mui/material/Accordion';
// import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getUser, setUser, getAccess, setAccess, getUserProjects, getUserGroups, inviteUser, deleteUser, deleteAccess, updateRepositoriesAccess } from '../../../apiConnector'
import { eventManager } from '../../../eventManager';
import { useError } from '../../contexts/ErrorContext';
import LoadingPlaceholder from '../../items/LoadingPlaceholder';
import BackButton from '../../items/BackButton';
import UserServiceAccessSelect from '../../items/UserServiceAccessSelect';
import UserRoleSelect from '../../items/UserRoleSelect';
import UserTypeSelect from '../../items/UserTypeSelect';
import ConfirmDialog from '../../dialogs/ConfirmDialog';
import { useNotification } from '../../contexts/NotificationContext';
import SaveButton from '../../items/SaveButton';

const userDefaultData = {
    email: "",
    display_name: "",
    svnuser: "",
    svnpass: "",
    service_access: [],
    roles: [],
    account_type: "user"
}

const EditUser = ({ match }) => {

    const [userData, setUserData] = useState();
    const [userGroups, setUserGroups] = useState();
    const [userProjects, setUserProjects] = useState();
    const [userIPs, setUserIPs] = useState([]);
    const [userPorts, setUserPorts] = useState([]);
    const [firewallMetadata, setFirewallMetadata] = useState();
    const [role, setRole] = useState([]);
    const [type, setType] = useState("");
    const [services, setServices] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(true);

    const history = useHistory();
    const { showError, hasError } = useError();
    const { addNotification } = useNotification();
    const id = match.params.id;

    const fetchData = async () => {
        let user = await getUser(id);

        if (hasError.current) return;

        const groups = await getUserGroups(id);
        const projects = await getUserProjects(id);
        const access = await getAccess("", user.data.email);

        user = { ...userDefaultData, ...user.data };

        setUserData(user);
        setUserGroups(groups.data.map(g => g));
        setUserProjects(projects.data.map(p => p));

        var ips = access?.data?.gcpIPs ?? [];
        // firewallIPs = firewallIPs.filter(function (ip, pos) { return firewallIPs.indexOf(ip) == pos })

        var ports = access?.data?.gcpPorts ?? [];
        // firewallPorts = firewallPorts?.filter(function (port, pos) { return firewallPorts.indexOf(port) == pos })

        setRole(user.roles);
        setType(user.account_type);
        setServices(user.service_access);

        setUserIPs(ips);
        setUserPorts(ports);
        setFirewallMetadata(access?.data?.metadata);

        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    useEffect(() => {
        const handleError = (error) => { showError(error); if (error?.response?.status === 404) { history.goBack(); } };
        eventManager.subscribe('api-error', handleError);
        return () => { eventManager.unsubscribe('api-error', handleError); };
    }, []);

    const handleSave = async (e) => {
        await setUser(id, { ip: userIPs, roles: role, account_type: type, service_access: services });
        const data = await getUser(id);
        await setAccess(data.data);
        await fetchData();
        addNotification("Saved!");
    };

    const handleDelete = () => { setOpenDialog(true); };

    const handleConfirmDelete = async () => {
        await deleteAccess(userData.email);
        await deleteUser(id);
        await updateRepositoriesAccess();
        addNotification("User Deleted!");
        setOpenDialog(false);
        history.goBack();
    };
    const handleResetPassword = async (e) => {
        await inviteUser(id, true);
        addNotification("Password reset email sent!");
    }

    const activeChanges = useMemo(() => {

        const currentRoles = new Set(userData?.roles);
        const selectedRoles = new Set(role);
        const rolesMatch = selectedRoles.size === currentRoles.size && [...selectedRoles].every(id => currentRoles.has(id));

        const typeMatch = userData?.account_type === type;

        const currentServices = new Set(userData?.service_access);
        const selectedServices = new Set(services);
        const servicesMatch = selectedServices.size === currentServices.size && [...selectedServices].every(id => currentServices.has(id));

        return rolesMatch && typeMatch && servicesMatch;

    }, [userData, role, type, services]);

    if (loading) return (<LoadingPlaceholder />)

    return (
        <>
            <Paper sx={{ p: 2 }} elevation={4}>
                <Stack direction="row" spacing={1}>
                    <BackButton />
                </Stack>
                <Stack spacing={1} sx={{ mt: 1, mb: 2 }} alignItems="center" justifyContent="center">
                    <Stack direction="row" spacing={1}>
                        <Typography variant="h4">{userData.display_name}</Typography>
                    </Stack>
                    <Typography variant="body1">{userData.email}</Typography>
                    <Chip variant="outlined" label={userData?.account_type} />
                    {userData?.protected && (<Typography variant="body2">This user is protected from deletion.</Typography>)}

                </Stack>
                <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 2 }} alignItems="center" justifyContent="center">
                    <SaveButton active={!activeChanges} onSave={handleSave} />
                    <Button sx={{ borderRadius: 28 }} color="primary" variant="outlined" onClick={handleResetPassword}>RESET PASSWORD</Button>
                    {!userData?.protected && (<Button sx={{ borderRadius: 28 }} color="error" variant="outlined" onClick={handleDelete}>DELETE</Button>)}
                </Stack>
                <Divider sx={{ mb: 1 }} />
                <Stack direction="row" spacing={2} alignItems="center">
                    <UserRoleSelect role={role} onSelect={setRole} />
                    <UserTypeSelect type={type} onSelect={setType} />
                </Stack>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <UserServiceAccessSelect services={services} onSelect={setServices} />
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Typography variant="body2">
                    Default services for users are SVN and UPM. The rest should be turned on only if necessary.
                    <br />This setting opens the following ports on the firewall for the user:
                    <br />- SVN (project repository): 3690
                    <br />- Jenkins (ci/cd service): 8080, 8443
                    <br />- SSH (remote access to VMs in the cloud): 22
                    <br />- RDP (windows remote desktop): 3389
                    <br />- UPM (internal unity packages repository): 4873
                    <br />- HTTP (default www port): 80
                    <br />- High Ports: 30000-65535
                </Typography>
            </Paper>

            <Paper sx={{ mt: 1, p: 2 }} elevation={4}>

                <Accordion elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Box>User Groups</Box>
                            <Chip label={userGroups && userGroups.length} variant="outlined" />
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        {userGroups ? userGroups.map((g) => (
                            <Chip onClick={() => history.push(`/admin/groups/edit/${g.name}`)} sx={{ p: 0.2, m: 0.2 }} key={g.name} label={g.name} size="small" variant="outlined" />
                        )) : null}
                    </AccordionDetails>
                </Accordion>

                <Accordion elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Box>User Projects</Box>
                            <Chip label={userProjects && userProjects.length} variant="outlined" />
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        {userProjects ? userProjects.map((p) => (
                            <Chip onClick={() => history.push(`/admin/projects/edit/${p.name}`)} sx={{ p: 0.2, m: 0.2 }} key={p.name} label={p.name} size="small" variant="outlined" />
                        )) : null}
                    </AccordionDetails>
                </Accordion>

                <Accordion elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>User Firewall Data</AccordionSummary>
                    <AccordionDetails>
                        <Stack sx={{ mb: 1, pl: 1 }} direction="row" spacing={1} alignItems="center">
                            <Typography variant="body2" >Rule Name:</Typography>
                            <Chip label={firewallMetadata?.name} size="small" variant="outlined" />
                        </Stack>
                        <Stack sx={{ mb: 1, pl: 1 }} direction="row" spacing={1} alignItems="center">
                            <Typography variant="body2" >Rule Priority:</Typography>
                            <Chip label={firewallMetadata?.priority} size="small" variant="outlined" />
                        </Stack>
                        <Stack sx={{ mb: 1, pl: 1 }} direction="row" spacing={1} alignItems="center">
                            <Typography variant="body2" >User IP:</Typography>
                            {userIPs ? userIPs.map((v) => (<Chip key={v} label={v} size="small" variant="outlined" />)) : null}
                        </Stack>
                        <Stack sx={{ pl: 1 }} direction="row" spacing={1} alignItems="center">
                            <Typography variant="body2" >Ports:</Typography>
                            {userPorts ? userPorts.map((v) => (<Chip key={v} label={v} size="small" variant="outlined" />)) : null}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </Paper>

            <ConfirmDialog
                open={openDialog}
                header="Confirm Delete"
                message="Are you sure you want to delete this user?"
                onConfirm={handleConfirmDelete}
                onCancel={() => setOpenDialog(false)}
            />
        </>
    )
}

export default EditUser