import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackButton = () => {
    const history = useHistory();

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <Button sx={{ borderRadius: 28 }} variant="contained" startIcon={<ArrowBackIcon />} onClick={handleGoBack}>
            Back
        </Button>
    );
};

export default BackButton;